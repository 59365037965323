import styled, { css } from "styled-components";
import { Transition } from "react-transition-group";
import "./styles/App.css";
import { useRef, useState } from "react";
import { BackIcon } from "./components/Icon";

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

const DEBUG = false;

const banners = [
  "WEEK1-Prodigal-Son.png",
  "WEEK2-Repentance.png",
  "WEEK3-Compassion--Mary-Magdalene.png",
  "WEEK4-I-am-Lord-Your-GOD.png",
  "WEEK5-Annunciation.png",
  "WEEK6-Abraham.png",
  "WEEK7-Passover.png",
];

const cards = [
  {
    banner: 0,
    date: "Wed, Feb 22",
    title: "Ash Wednesday",
    firstReading: "Joel 2.12-18",
    psalm: "Ps 51.1-2,3-4a, 10-11, 12,15 2 Corinthians 5.20-6.2",
    gospel: "Matthew 6.1-6, 16-18",
    gospelText:
      "Return to me with all your heart, with fasting, with weeping, and with mourning",
  },
  {
    banner: 0,
    date: "Thu, Feb 23",
    firstReading: "Deuteronomy 30.15-20",
    psalm: "Ps 1.1-2,3, 4-6",
    gospel: "Luke 9.22-25",
    gospelText:
      "If you obey the commandments by loving the Lord your God, by walking in His ways, and by keeping His rules, then you shall live and multiply",
  },
  {
    banner: 0,
    date: "Fri, Feb 24",
    firstReading: "Isaiah 58.1-9",
    psalm: "Ps 51.1-2,3-4a, 16-17",
    gospel: "Matthew 9.14-15",
    gospelText:
      "Is this not the fast that I choose: to loose the bonds of wickedness, to undo the straps of the yoke, to let the oppressed go free, to share your bread with the hungry, and bring the homeless poor into your house…",
  },
  {
    banner: 0,
    date: "Sat, Feb 25",
    firstReading: "Isaiah 58.9b-14",
    psalm: "Ps 86.1-2ab,2c-4,5-6",
    gospel: "Luke 5.27-32",
    gospelText:
      "If you pour yourself out for the hungry and satisfy the desire of the afflicted, then shall your light rise in the darkness and your gloom be as the noonday.",
  },
  {
    banner: 0,
    date: "Sun, Feb 26",
    title: "1st Sunday of Lent",
    firstReading: "Genesis 2.7-9,16-18, 25;3.1-7",
    psalm: "Ps 51.1-2,3-4a,10-11,12,15	Romans 5.12-19",
    gospel: "Matthew 4.1-11",
    gospelText:
      "It is written, 'One does not live by bread alone, but by every word that comes from the mouth of God.'",
  },
  {
    banner: 1,
    date: "Mon, Feb 27",
    firstReading: "Leviticus 19.1-2, 11-18",
    psalm: "Ps 19.7,8,9,14",
    gospel: "Matthew 25.31-46",
    gospelText:
      "You shall not take vengeance or bear a grudge against the sons of your own people, but you shall love your neighbor as yourself: I am the Lord",
  },
  {
    banner: 1,
    date: "Tue, Feb 28",
    firstReading: "Isaiah 55.10-11",
    psalm: "Ps 34.3-4,5-6,15-16,17-18",
    gospel: "Matthew 6.7-15",
    gospelText:
      "The word that goes out from My mouth shall not return to Me empty, but it shall accomplish that which I purpose, and shall succeed in the thing for which I sent it",
  },
  {
    banner: 1,
    date: "Wed, Mar 01",
    firstReading: "Jonah 3.1-10",
    psalm: "Ps 51.1-2,10-11,16-17",
    gospel: "Luke 11.29-32",
    gospelText:
      "Let everyone turn from his evil way and from the violence in his hands, that God may relent and turn from His fierce anger, so that we may not perish.",
  },
  {
    date: "Thu, Mar 02",
    firstReading: "Esther 14.1,3-5,12-14",
    psalm: "Ps 138.1-2a,2bc-3,7c-8",
    gospel: "Matthew 7.7-12",
    gospelText:
      "Remember, O Lord; make yourself known in this time of affliction, and give me courage, O King and Master of all dominion!",
  },
  {
    date: "Fri, Mar 03",
    firstReading: "Ezekiel 18.21-28",
    psalm: "Ps 130.1-2,3-4,5-6	",
    gospel: "Matthew 5.20-26",
    gospelText:
      "When a wicked person turns away from all his sins and keeps all my statutes and does what is just and right, he shall surely live; he shall not die.",
  },
  {
    banner: 1,
    date: "Sat, Mar 04",
    firstReading: "Deuteronomy 26.16-19",
    psalm: "Ps 119.1-2,4-5,7-8	",
    gospel: "Matthew 5.43-48",
    gospelText:
      "The Lord has declared that if you are to keep all his commandments, He will set you in praise and honor high above all nations that He has made…",
  },
  {
    banner: 1,
    date: "Sun, Mar 05",
    title: "2nd Sunday of Lent",
    firstReading: "Genesis 12.1-4",
    psalm: "Ps 33.4-5, 18-19, 20, 22 2 Timothy 1.8b-10",
    gospel: "Matthew 17.1-9",
    gospelText:
      "Jesus took Peter, James, and John his brother, and led them up a high mountain by themselves. And he was transfigured before them; his face shone like the sun and his clothes became white as light.",
  },
  {
    banner: 2,
    date: "Mon, Mar 06",
    firstReading: "Daniel 9.3,4b-10",
    psalm: "Ps 79.8,9,11,13",
    gospel: "Luke 6.36-38",
    gospelText:
      "I turned my face to the Lord God, seeking Him by prayer  and pleas for mercy, with fasting and sackcloth and ashes.",
  },
  {
    banner: 2,
    date: "Tue, Mar 07",
    firstReading: "Isaiah 1.10,16-20, 27-28,31",
    psalm: "Ps 50.8-9, 16bc-17, 21,23	",
    gospel: "Matthew 23.1-12",
    gospelText:
      "Wash yourselves, make yourselves clean; remove the evil of your deeds from before my eyes; cease to do evil, learn to do good; seek justice, correct oppression…",
  },
  {
    banner: 2,
    date: "Wed, Mar 08",
    firstReading: "Jeremiah 18.18-20",
    psalm: "Ps 31.4-5, 13,14-15	",
    gospel: "Matthew 20.17-28",
    gospelText:
      "Hear me O Lord, listen to the voice of my adversaries. Should good be repaid with evil?",
  },
  {
    banner: 2,
    date: "Thu, Mar 09",
    firstReading: "Jeremiah 17.5-10",
    psalm: "Ps 1.1-2, 3,4,6",
    gospel: "Luke 16.19-31",
    gospelText:
      "Thus says the Lord: Cursed in the man who makes flesh his strength, whose heart turns away from the Lord. He is like a shrub in the desert, and shall not see any good come.",
  },
  {
    banner: 2,
    date: "Fri, Mar 10",
    firstReading: "Genesis 37.3-4, 12-13a, 17b-28",
    psalm: "Ps 105.16-17,18-19,20-21",
    gospel: "Matthew 21.33-43, 45-46",
    gospelText:
      "Here comes the dreamer. Come now, let us kill him and throw him into one of the pits. Then we will say a fierce animal has devoured him, and we will see what will become of his dreams.",
  },
  {
    banner: 2,
    date: "Sat, Mar 11",
    firstReading: "Micah 7.14-15, 18-20",
    psalm: "Ps 103.1-2,3-4,9-10,11-12",
    gospel: "Luke 15.1-3, 11-32",
    gospelText:
      "Who is like You, pardoning iniquity and passing over transgression for the remnant of his inheritance? He does not retain His anger forever, because He delights in steadfast love.",
  },
  {
    banner: 2,
    date: "Sun, Mar 12",
    title: "3rd Sunday of Lent",
    firstReading: "Exodus 17.3-7",
    psalm: "Ps 95.1-2,6-7ab,7c-9 Romans 5.1-2, 5-8",
    gospel: "John 4.5-42",
    gospelText:
      'Jesus answered and said to her, "Everyone who drinks this water will be thirsty again; but whoever drinks the water I shall give will never thirst; the water I shall give will become in him a spring of water welling up to eternal life."',
  },
  {
    banner: 3,
    date: "Mon, Mar 13",
    firstReading: "2 Kings 5.1-15a",
    psalm: "Ps 42.1-2,3;43.3,4",
    gospel: "Luke 4.24-30",
    gospelText:
      "Go and wash in the Jordan seven times, and your flesh shall be restored, and you shall be clean.",
  },
  {
    banner: 3,
    date: "Tue, Mar 14",
    firstReading: "Daniel 3.25, 34-43",
    psalm: "Ps 25.4-5a,6-7,8-9",
    gospel: "Matthew 18.21-35",
    gospelText:
      "I see four men unbound, walking in the midst of the fire, and they are not hurt; and the appearance of the fourth is like a son of the gods.",
  },
  {
    banner: 3,
    date: "Wed, Mar 15",
    firstReading: "Deuteronomy 4.1,5-9",
    psalm: "Ps 147.12-13,15-16,19-20",
    gospel: "Matthew 5.17-19",
    gospelText:
      "For what great nation is there that has a god so near to it as the Lord our God is to us, whenever we call upon Him?",
  },
  {
    banner: 3,
    date: "Thu, Mar 16",
    firstReading: "Jeremiah 7.23-28",
    psalm: "Ps 95.1-2,6-7ab,7c-9",
    gospel: "Luke 11.14-23",
    gospelText:
      "Obey My voice, and I will be your God, and you shall be my people. And walk in all the way that I command you, that it may be well with you.",
  },
  {
    banner: 3,
    date: "Fri, Mar 17",
    firstReading: "Hosea 14.1-9",
    psalm: "Ps 81.5c-7a, 7bc-8,9-10ab,13,16",
    gospel: "Mark 12.28b-34",
    gospelText:
      "Return, O Israel, to the Lord your God, for you have stumbled because of your iniquity. Take with you words nd return to the Lord.",
  },
  {
    banner: 3,
    date: "Sat, Mar 18",
    firstReading: "Hosea 5.15b-6.6",
    psalm: "Ps 51.1-2,16-17,18-19ab",
    gospel: "Luke 18.9-14",
    gospelText:
      "Come let us return to the Lord for He has torn us, that He may heal us; He has struck us down, and He will bind us up.",
  },
  {
    banner: 3,
    date: "Sun, Mar 19",
    title: "4th Sunday of Lent",
    firstReading: "1 Samuel 16.1b, 6-7, 10-13",
    psalm: "Ps 23.1-3a,3b-4,5,6	Ephesians 5.8-14",
    gospel: "John 9.1-41",
    gospelText:
      'When he had said this, he spat on the ground and made clay with the saliva, and smeared the clay on his eyes, and said to him, "Go wash in the Pool of Siloam" —which means Sent—. So he went and washed, and came back able to see.',
  },
  {
    banner: 4,
    date: "Mon, Mar 20",
    title: "St Joseph, Spouse of the Blessed Virgin Mary",
    firstReading: "2 Samuel 7.4-5a, 12-14a",
    psalm: "Ps 89.1-2,3-4,26,28	Romans 4.13,16-18,22",
    gospel: "Matthew 1.16,18-21,24a",
    gospelText:
      "Go and tell my servant David: Thus says the Lord, would you build me a house to dwell in?",
  },
  {
    banner: 4,
    date: "Tue, Mar 21",
    firstReading: "Ezekiel 47.1-9,12",
    psalm: "Ps 46.1-2,4-5,7-8",
    gospel: "John 5.1-16",
    gospelText:
      "And wherever the river goes, every living creature that swarms will live, and there will be very many fish… everything will live where the river goes.",
  },
  {
    banner: 4,
    date: "Wed, Mar 22",
    firstReading: "Isaiah 49.8-15",
    psalm: "Ps 145.8-9,13cd-14,17-18",
    gospel: "John 5.16-30",
    gospelText:
      "Can a woman forget her nursing child, that she should have no compassion on the son of her womb? Even these may forget, yet I will not forget you.",
  },
  {
    banner: 4,
    date: "Thu, Mar 23",
    firstReading: "Exodus 32.7-14",
    psalm: "Ps 106.19-20,21-22,23	",
    gospel: "John 5.18, 31-47",
    gospelText:
      "Moses implored the Lord God and said, 'Oh Lord, why does your wrath burn hot against your people?....Turn from your burning anger and relent from this disaster against your people.",
  },
  {
    banner: 4,
    date: "Fri, Mar 24",
    firstReading: "Wisdom 2.1a, 12-22",
    psalm: "Ps 34.16-17,18-19,20,22",
    gospel: "John 7.1-2, 10, 25-30",
    gospelText:
      "For God created human beings to be immortal, He made them as an image of His own nature.",
  },
  {
    banner: 4,
    date: "Sat, Mar 25",
    title: "The Annunciation of the Lord",
    firstReading: "Isaiah 7.10-14; 8.10d",
    psalm: "Ps 40.6,7-8,9,10	Hebrews 10.4-10",
    gospel: "Luke 1.26-38",
    gospelText:
      "The Lord Himself will give you a sign. Behold a young woman shall conceive and bear a Son, and shall call His name Immanuel.",
  },
  {
    banner: 4,
    date: "Sun, Mar 26",
    title: "5th Sunday of Lent",
    firstReading: "Ezekiel 37.12-14",
    psalm: "Ps 130.1-2,3-4,5-6,7b-8	Romans 8.8-11",
    gospel: "John 11.1-45",
    gospelText:
      'She fell at his feet and said to him, "Lord, if you had been here, my brother would not have died.” When Jesus saw her weeping and the Jews who had come with her weeping, he became perturbed and deeply troubled, and said, "Where have you laid him?” They said to him, “Sir, come and see.” And Jesus wept.',
  },
  {
    banner: 5,
    date: "Mon, Mar 27",
    firstReading: "Daniel 13.1-9,15-17,19-30,33-64",
    psalm: "Ps 23.1-3a,3b-4,5,6",
    gospel: "John 8.1-11",
    gospelText:
      "And many of those who sleep in the dust of the earth shall awake, some to everlasting life, and some to shame and everlasting contempt.",
  },
  {
    banner: 5,
    date: "Tue, Mar 28",
    firstReading: "Numbers 21.4-9",
    psalm: "Ps 102.1-2,15-17,18-20",
    gospel: "John 8.21-30",
    gospelText:
      "The people came to Moses and said:' We have sinned, for we have spoken against the Lord and against you; pray to the Lord that He take away the serpents from us.' So Moses prayed for the people.",
  },
  {
    banner: 5,
    date: "Wed, Mar 29",
    firstReading: "Daniel 3.13-20,24,49-50,91-95 Daniel 3.52,53,54,55,56",
    gospel: "John 8.31-42",
    gospelText:
      "If it be so, our God whom we serve is able to deliver us from the burning fiery furnace; and He will deliver us out of your hand, O king.",
  },
  {
    banner: 5,
    date: "Thu, Mar 30",
    firstReading: "Genesis 17.3-9",
    psalm: "Ps 105.4-5,6-7,8-9",
    gospel: "John 8.51-59",
    gospelText:
      "Abraham fell on his face and God said to him: Behold my covenant is with you, and you shall be the father of a multitude of nations.",
  },
  {
    banner: 5,
    date: "Fri, Mar 31",
    firstReading: "Jeremiah 20.7, 10-13",
    psalm: "Ps 18.1-2ab,2c-3,4-5,6",
    gospel: "John 10.31-42",
    gospelText:
      "Sing to the Lord, praise the Lord! For He has delivered the life of the needy from the hand of evildoers.",
  },
  {
    banner: 5,
    date: "Sat, Apr 01",
    firstReading: "Ezekiel 37.21-18	Jeremiah 31.10, 11-12ab,13",
    gospel: "John 11.45-57",
    gospelText:
      "Thus says the Lord God: Behold, I will take the people of Israel from the nations among which they have gone, and will gather them from all sides, and bring them to their own land.",
  },
  {
    banner: 5,
    date: "Sun, Apr 02",
    title: "Palm Sunday",
    firstReading: "Isaiah 50.4-7",
    psalm: "Ps 22.7-8,16-17,18-19,22-23	Philippians 2.6-11",
    gospel: "Matthew 26.14-27.66",
    gospelText:
      '"My Father, if it is possible, let this cup pass from me; yet, not as I will, but as you will."',
  },
  {
    banner: 6,
    date: "Mon, Apr 03",
    title: "Holy Monday",
    firstReading: "Isaiah 42.1-7",
    psalm: "Ps 27.1,2,3,13-15	",
    gospel: "John 12.1-11",
    gospelText:
      "I am the Lord, I have called you in righteousness, I have taken you by the hand and kept you; I have given you as a covenant to the people, a light to the nations, to open the eyes that are blind, to bring out prisoners from the dungeon…",
  },
  {
    banner: 6,
    date: "Tue, Apr 04",
    title: "Holy Tuesday",
    firstReading: "Isaiah 49.1-6",
    psalm: "Ps 71.1-2,3,5-6,15,17	",
    gospel: "John 13.21-33, 36-38",
    gospelText:
      "I have labored in vain, I have spent my strength for nothing and vanity; yet surely my right is with the Lord, and my recompense with my God.",
  },
  {
    banner: 6,
    date: "Wed, Apr 05",
    title: "Holy Wednesday",
    firstReading: "Isaiah 50.4-9a",
    psalm: "Ps 69.7-9,20-21,30,32-33",
    gospel: "Matthew 26.14-26",
    gospelText:
      "The Lord God has given me the tongue of those who are taught, that I may know how to sustain with a word him that is weary. Morning by morning he wakens, he wakens my ear to hear as those who are taught.",
  },
  {
    banner: 6,
    date: "Thu, Apr 06",
    title: "Maundy Thursday/Lord's Supper",
    firstReading: "Exodus 12.1-8,11-14",
    psalm: "Ps 116.12-13,15,16bc,17-18	1 Corinthians 11.23-26",
    gospel: "John 13.1-15",
    gospelText:
      "The blood shall be a sign for you, upon the houses where you are; and when I see the blood, I will pass over you, and no plague shall fall upon you to destroy you, when I smite the land of Egypt.",
  },
  {
    banner: 6,
    date: "Fri, Apr 07",
    title: "Good Friday/Passion of the Lord",
    firstReading: "Isaiah 52.13-53.12",
    psalm: "Ps 31.1,5, 11-12, 14-15,16,24	Hebrews 4.14-16; 5.7-9",
    gospel: "John 18.1-19.42",
    gospelText:
      "He was wounded for our transgressions, He was bruised for our iniquities; upon Him was the chastisement that made us whole, and with His stripes we are healed.",
  },
  {
    banner: 6,
    date: "Sat, Apr 08",
    title: "Easter Vigil",
    firstReading: "Genesis 1.1-2.2",
    psalm: "Ps 104.1-2a,5-6,10,12,13-14,24,35	Romans 6.3-11",
    gospel: "Matthew 28.1-10",
    gospelText:
      "And God said, 'Let there be light', and there was light. And God saw that the light was good; and God separated the light from the darkness.",
  },
  {
    banner: 6,
    date: "Sun, Apr 09",
    title: "Easter Sun",
    firstReading: "Acts 10.34a, 37-43",
    psalm: "Ps 118.1-2,16-17,22-23	Colossians 3.1-4",
    gospel: "John 20.1-18",
    gospelText:
      "Then the other disciple also went in, the one who had arrived at the tomb first, and he saw and believed. For they did not yet understand the Scripture that he had to rise from the dead.",
  },
];

const SubHeading = styled.h2`
  color: white;
  margin-bottom: 24px;
  font-size: 32px;
`;
const Banner = styled.img`
  width: 100%;
  height: 200px;
  object-fit: contain;
  margin-bottom: 40px;
`;
const P = styled.p`
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  font-size: 1.2rem;
  margin: 0 auto 24px;
  padding: 0 40px;
  max-width: 1060px;

  ${({ $css }) => $css && $css}
`;
const Main = styled.main`
  @-webkit-keyframes Background {
    0% {
      background-position: 79% 0%;
    }
    50% {
      background-position: 22% 100%;
    }
    100% {
      background-position: 79% 0%;
    }
  }
  @-moz-keyframes Background {
    0% {
      background-position: 79% 0%;
    }
    50% {
      background-position: 22% 100%;
    }
    100% {
      background-position: 79% 0%;
    }
  }
  @keyframes Background {
    0% {
      background-position: 79% 0%;
    }
    50% {
      background-position: 22% 100%;
    }
    100% {
      background-position: 79% 0%;
    }
  }

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: rgb(113 130 116 / 0%);

  transition: background-color 0.5s ease-in;

  ${({ step }) => {
    if (step !== "start") {
      return `
				background: linear-gradient(222deg,#3c3175,#382e44,#382356);
			background-size: 600% 600%;

			-webkit-animation: Background 60s ease infinite;
			-moz-animation: Background 60s ease infinite;
			animation: Background 60s ease infinite;
				// background-color: rgb(113 130 116);
			`;
    }
  }}
`;
const CTA = styled.a`
  text-shadow: 0px 2px 4px #376b4c;
  font-size: 18px;
  font-weight: 700;
  font-family: "Source Sans Pro", sans-serif;
  padding: 8px 32px;
  border-radius: 48px;
  background-color: #29945d;
  color: white;
  border: none;
  box-shadow: 0px 5px 8px 0px rgb(24 99 32 / 44%);
  text-decoration: none;
  user-select: none;

  transition: background-color 0.5s ease-out;

  &:active {
    color: white;
  }
  &:active {
    color: white;
  }
  &:hover {
    background-color: #126038;
    cursor: pointer;
  }
`;
const Button = styled.button`
  text-shadow: 0px 2px 4px #376b4c;
  font-size: 24px;
  font-weight: 700;
  font-family: "Source Sans Pro", sans-serif;
  padding: 8px 32px;
  border-radius: 48px;
  background-color: #29945d;
  color: white;
  border: none;
  box-shadow: 0px 5px 8px 0px rgb(24 99 32 / 44%);
  text-decoration: none;
  user-select: none;

  transition: background-color 0.5s ease-out;

  &:active {
    color: white;
  }
  &:active {
    color: white;
  }
  &:hover {
    background-color: #126038;
    cursor: pointer;
  }
`;
const LentenCardSection = styled.section`
  display: grid;

  grid-template-columns: repeat(1, 1fr);
  padding: 40px 40px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    padding: 40px 120px;
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
    padding: 40px 120px;
  }
`;

const GridButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 80%;

  @media (min-width: 768px) {
    max-width: 300px;
  }

  user-select: none;
  padding: 24px;
  margin: 16px;
  border-radius: 16px;

  box-shadow: 0px 4px 8px rgb(0 0 0 / 20%);
  background-color: ${({ $past }) =>
    $past ? `rgb(45 36 138 / 50%)` : `#2d248a`};

  ${({ state }) =>
    ["entering", "exiting"].includes(state)
      ? `
		transition: opacity 1s ease-in;
		`
      : `
		transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out, transform 0.8s ease-in;
		`}
  ${({ state }) =>
    state === "entered" &&
    `
		&:hover {
			cursor: pointer;
			opacity: 1;
			box-shadow: 0px 4px 8px rgb(0 0 0 / 40%);
		}

		&:active {
			opacity: 1;
			background-color: #614ad3;
		}
	`}
    ${({ state }) =>
    state === "entering" &&
    `
		opacity: 0;
		transform: translateY(-25%);
		`}
    ${({ state }) =>
    state === "entered" &&
    `
		opacity: 0.9;
		transform: translateY(0%);
		`}
    ${({ state }) =>
    state === "exiting" &&
    `
		opacity: 1;
		`}
    ${({ state }) =>
    state === "exited" &&
    `
		opacity: 0;
		`};
`;

const LentenCardTitle = styled.span`
  font-family: "Source Sans Pro", sans-serif;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 2rem;
  text-shadow: 0px 4px 8px black;
  color: white;
  min-height: 84px;
  ${({ $css }) => $css && $css}
`;
const LentenCardSubtitle = styled.span`
  font-family: "Source Sans Pro", sans-serif;
  display: block;
  font-weight: bold;
  font-size: 1.6rem;
  color: white;
  ${({ $css }) => $css && $css}
`;
const LentenCardContent = styled.span`
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.4rem;
  color: white;
  min-height: 30px;
`;

const MeditationSection = styled.section`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  ${({ $expandHeight }) =>
    $expandHeight &&
    `
		align-items: flex-start;
		height: 160vh;

	`}
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  min-width: 50%;

  button {
    margin: 0 0 40px 0;
    width: 100%;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: flex-start;
    button {
      margin: 0 24px 0 0;
    }
  }
`;
const OpacityTransition = styled.div`
  opacity: 0;

  transition: opacity 0.5s ease-in-out;
  ${({ state }) =>
    state === "entering" &&
    `
		opacity: 0;
	`}
  ${({ state }) =>
    state === "entered" &&
    `
		opacity: 1;
	`}
      ${({ state }) =>
    state === "exiting" &&
    `
		opacity: 1;
	`}
      ${({ state }) =>
    state === "exited" &&
    `
		opacity: 0;
		`};
`;

const TextContainer = styled.div`
  padding: 0 40px;

  h2 {
    font-size: 24px;
    color: white;
  }

  @media (min-width: 768px) {
    padding: 0 120px;

    h2 {
      font-size: 36px;
    }
  }
`;

const Poster = styled.img`
  height: auto;
  width: 50vw;
  margin: 0 auto;
  box-shadow: 0 0 10px rgb(113 130 116) inset;

  @media (min-width: 768px) {
    height: 50vh;
    width: auto;
    margin: 0 auto;
  }
`;

const BackButton = styled.button`
  position: fixed;
  left: 24px;
  bottom: 24px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  opacity: 0.9;

  transition: opacity 0.2s ease-in-out;
  &:hover {
    cursor: pointer;
    opacity: 1;
  }

  svg {
    color: #8d9e8d80;
  }

  @media (min-width: 768px) {
    left: 40px;
    bottom: 40px;
    width: 64px;
    height: 64px;
  }
`;

// const Audio = styled.audio`
//   height: 0;
//   width: 0;
//   position: absolute;
//   left: -1000px;
// `;

const TIMING = {
  step1: DEBUG ? 0 : 5000,
  step2: DEBUG ? 0 : 2000,
  step3: DEBUG ? 0 : 3000,
  step4: DEBUG ? 0 : 2000,
  step5: DEBUG ? 0 : 3000,
  step6: DEBUG ? 0 : 5000,
  step7: DEBUG ? 0 : 2000,
  step8: DEBUG ? 0 : 3000,
  step9: DEBUG ? 0 : 5000,
  fadeOut: 200,
  startMeditation: 1000,
  meditation: 1000,
  prepare: DEBUG ? 250 : 1000,
};

function App() {
  const [currentCard, setCurrentCard] = useState(null);
  const [step, setStep] = useState("start");
  const [prepareStep, setPrepareStep] = useState("start");

  let meditationComplete = useRef();
  let audioRef = useRef();
  let meditationDuration = useRef();
  let meditationStart = useRef();
  let interval = useRef();
  let currentTimeout = useRef();

  const loadMusic = () => {
    console.log("loading music");
    const _audio = new Audio(`/bg-${getRandomInt(14)}.mp3`);
    _audio.load();

    _audio.addEventListener("ended", () => {
      if (!meditationComplete.current) {
        audioRef.current.pause();
        loadMusic();
      }
    });

    _audio.addEventListener("canplaythrough", () => {
      console.log("audio loaded");
      audioRef.current = _audio;

      if (!meditationComplete.current) {
        audioRef.current.play();
      }
      meditationComplete.current = false;
    });
  };

  const setFadeOut = (fadeOut) => {
    const fadeAudio = setInterval(() => {
      const fadePoint = fadeOut ?? audioRef.current.duration - 5;
      if (
        audioRef.current.currentTime >= fadePoint &&
        audioRef.current.volume !== 0
      ) {
        audioRef.current.volume =
          audioRef.current.volume - 0.1 < 0 ? 0 : audioRef.current.volume - 0.1;
      }

      if (audioRef.current.volume < 0.003) {
        clearInterval(fadeAudio);
      }
    }, TIMING.fadeOut);
  };

  const reset = () => {
    audioRef.current.pause();
    setCurrentCard(null);
    setPrepareStep("start");
    setStep("start");
    meditationDuration.current = undefined;
    meditationStart.current = undefined;
    interval.current = undefined;
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      meditationComplete.current = true;
    }
  };

  const handleCardClick = (id) => {
    const [activeCard] = cards.filter((card) => card.date === id);
    setCurrentCard(activeCard);
    setStep("meditation");
  };

  const handlePrepareMeditation = (duration) => {
    meditationDuration.current = duration;
    setStep("prepare");
    loadMusic();
  };

  const handleStartMeditation = () => {
    setStep("meditating");
    const start = new Date().valueOf();
    meditationStart.current = start;

    interval.current = setInterval(() => {
      const now = new Date().valueOf();
      if (
        Math.floor((now - meditationStart.current) / 1000) >=
        meditationDuration.current
      ) {
        clearInterval(interval.current);
        setStep("complete");
        setFadeOut(0);
        meditationComplete.current = true;
      }
    }, TIMING.startMeditation);
  };

  if (DEBUG) console.log("DEBUG:", currentCard, step, prepareStep);

  return (
    <Main step={step}>
      {!currentCard && (
        <>
          <P
            style={{
              width: "100vw",
              padding: 8,
              backgroundColor: "#93ceb0",
              margin: 0,
              textAlign: "center",
            }}
            $css={css`
              @media (min-width: 768px) {
                display: none;
              }
            `}
          >
            Scroll down for reflections
          </P>
          <h1 style={{ margin: 24 }}>Lenten Journey</h1>
          <P>
            The ANCOP Lenten Journey give us an opportunity to reflect on the
            meaning of Lent, and commemorate the passion, death and resurrection
            of Jesus. It is a time for renewal, which we achieve through prayer,
            fasting and giving.
          </P>
          <P>
            The ANCOP Lenten Journey guide us through daily prayer for the next
            40 days of Lent, with a designated Bible passage each day, for each
            of us to read and ponder. Our daily Lenten prayer is a time to
            recognize our faults, ask for forgiveness, and draw from God’s grace
            for mercy and compassion.
          </P>
          <P>
            The ANCOP Lenten Journey remind us of the importance of fasting-- a
            way of giving up something we are so attached to. Fasting goes
            beyond pious dieting. It is more meaningful if there is a change of
            heart, giving up resentments and ill will toward others, resulting
            in healing of heartaches and burdens we carry.
          </P>
          <P>
            Finally, ANCOP Lenten Journey lead us to make a sacrifice by
            providing an offering to help the poor and the marginalized, the
            needy and the helpless. Responding to God's grace and blessings with
            generosity to our poor brethren fulfills our duty as a Christian,
            making the coming of Easter truly joyful!
          </P>

          <CTA
            href="https://ancopcanada.donordrive.com/event/lentenjourney"
            target="_blank"
          >
            Make a Donation
          </CTA>
        </>
      )}
      <div
        style={{
          ...(step !== "start" && {
            height: 0,
            width: 0,
            overflow: "hidden",
          }),
        }}
      >
        <LentenCardSection>
          {cards.map((card) => {
            return (
              <Transition key={card.date} in timeout={500}>
                {(state) => (
                  <GridButton
                    key={card.date}
                    state={state}
                    onClick={() => {
                      handleCardClick(card.date);
                    }}
                    $past={new Date() > new Date(`${card.date}, 2023`)}
                  >
                    <LentenCardSubtitle>{card.date}</LentenCardSubtitle>
                    <LentenCardTitle>{card.gospel}</LentenCardTitle>
                    <LentenCardContent>{card?.title ?? " "}</LentenCardContent>
                  </GridButton>
                )}
              </Transition>
            );
          })}
        </LentenCardSection>
      </div>

      <div
        style={{
          ...(step === "start" && {
            height: 0,
            width: 0,
            overflow: "hidden",
          }),
        }}
      >
        <MeditationSection $expandHeight={step === "meditating"}>
          <Transition in={step === "meditation"} timeout={TIMING.meditation}>
            {(state) => (
              <div
                style={{
                  opacity: state === "entered" ? 1 : 0,
                  transition: "opacity 0.5s ease-in-out",
                  ...(step === "meditation"
                    ? {
                        width: "75vw",
                      }
                    : {
                        width: 0,
                        overflow: "hidden",
                      }),
                }}
              >
                <SubHeading>How long would you like to pray for?</SubHeading>
                <SubHeading
                  style={{
                    fontStyle: "italic",
                    fontSize: "1.6rem",
                    fontWeight: 500,
                  }}
                >
                  For the best experience, please turn on your audio.
                </SubHeading>
                <Row>
                  {/* TODO: Set 2 minutes to 120 after done debugging */}
                  <Button
                    style={{ minWidth: 200 }}
                    onClick={() => handlePrepareMeditation(120)}
                  >
                    2 minutes
                  </Button>
                  <Button
                    style={{ minWidth: 200 }}
                    onClick={() => handlePrepareMeditation(300)}
                  >
                    5 minutes
                  </Button>
                  <Button
                    style={{ minWidth: 200 }}
                    onClick={() => handlePrepareMeditation(600)}
                  >
                    10 minutes
                  </Button>
                </Row>
              </div>
            )}
          </Transition>
          {/* PREPARE STEPS */}
          <div
            style={{
              ...(step !== "prepare" && {
                width: 0,
                overflow: "hidden",
              }),
            }}
          >
            <Transition
              in={step === "prepare" && prepareStep === "start"}
              exit={true}
              timeout={TIMING.prepare}
              onEntered={() => {
                currentTimeout.current = setTimeout(() => {
                  setPrepareStep("1");
                }, TIMING.step1);
              }}
            >
              {(state) => (
                <OpacityTransition state={state}>
                  <TextContainer>
                    <h2>Please put anything away that might distract you.</h2>
                  </TextContainer>
                </OpacityTransition>
              )}
            </Transition>
            <Transition
              in={step === "prepare" && prepareStep === "1"}
              exit={true}
              timeout={TIMING.prepare}
              onEntered={() => {
                currentTimeout.current = setTimeout(() => {
                  setPrepareStep("2");
                }, TIMING.step2);
              }}
            >
              {(state) => (
                <OpacityTransition state={state}>
                  <TextContainer>
                    <h2>Breathe in</h2>
                  </TextContainer>
                </OpacityTransition>
              )}
            </Transition>
            <Transition
              in={step === "prepare" && prepareStep === "2"}
              exit={true}
              timeout={TIMING.prepare}
              onEntered={() => {
                currentTimeout.current = setTimeout(() => {
                  setPrepareStep("3");
                }, TIMING.step3);
              }}
            >
              {(state) => (
                <OpacityTransition state={state}>
                  <TextContainer>
                    <h2>Breathe out</h2>
                  </TextContainer>
                </OpacityTransition>
              )}
            </Transition>
            <Transition
              in={step === "prepare" && prepareStep === "3"}
              exit={true}
              timeout={TIMING.prepare}
              onEntered={() => {
                currentTimeout.current = setTimeout(() => {
                  setPrepareStep("4");
                }, TIMING.step4);
              }}
            >
              {(state) => (
                <OpacityTransition state={state}>
                  <TextContainer>
                    <h2>Breathe in</h2>
                  </TextContainer>
                </OpacityTransition>
              )}
            </Transition>
            <Transition
              in={step === "prepare" && prepareStep === "4"}
              exit={true}
              timeout={TIMING.prepare}
              onEntered={() => {
                currentTimeout.current = setTimeout(() => {
                  setPrepareStep("5");
                }, TIMING.step5);
              }}
            >
              {(state) => (
                <OpacityTransition state={state}>
                  <TextContainer>
                    <h2>Breathe out</h2>
                  </TextContainer>
                </OpacityTransition>
              )}
            </Transition>
            <Transition
              in={step === "prepare" && prepareStep === "5"}
              exit={true}
              timeout={TIMING.prepare}
              onEntered={() => {
                currentTimeout.current = setTimeout(() => {
                  setPrepareStep("6");
                }, TIMING.step6);
              }}
            >
              {(state) => (
                <OpacityTransition state={state}>
                  <TextContainer>
                    <h2>All glory and praise to You my Lord and my God.</h2>
                  </TextContainer>
                </OpacityTransition>
              )}
            </Transition>
            <Transition
              in={step === "prepare" && prepareStep === "6"}
              exit={true}
              timeout={TIMING.prepare}
              onEntered={() => {
                currentTimeout.current = setTimeout(() => {
                  setPrepareStep("7");
                }, TIMING.step7);
              }}
            >
              {(state) => (
                <OpacityTransition state={state}>
                  <TextContainer>
                    <h2>Breathe in</h2>
                  </TextContainer>
                </OpacityTransition>
              )}
            </Transition>
            <Transition
              in={step === "prepare" && prepareStep === "7"}
              exit={true}
              timeout={TIMING.prepare}
              onEntered={() => {
                currentTimeout.current = setTimeout(() => {
                  setPrepareStep("8");
                }, TIMING.step8);
              }}
            >
              {(state) => (
                <OpacityTransition state={state}>
                  <TextContainer>
                    <h2>Breathe out</h2>
                  </TextContainer>
                </OpacityTransition>
              )}
            </Transition>
            <Transition
              in={step === "prepare" && prepareStep === "8"}
              exit={true}
              timeout={TIMING.prepare}
              onEntered={() => {
                currentTimeout.current = setTimeout(() => {
                  // End preparation
                  handleStartMeditation();
                }, TIMING.step9);
              }}
            >
              {(state) => (
                <OpacityTransition state={state}>
                  <TextContainer>
                    <h2>You are my everything and You are enough for me.</h2>
                  </TextContainer>
                </OpacityTransition>
              )}
            </Transition>
          </div>
          <div
            style={{
              ...(step !== "meditating" && {
                width: 0,
              }),
            }}
          >
            <Transition in={step === "meditating"} timeout={500}>
              {(state) => {
                return (
                  <OpacityTransition state={state}>
                    {currentCard && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          margin: "24px 0",
                        }}
                      >
                        <LentenCardSubtitle
                          $css={css`
                            text-align: center;
                            margin: 24px 0;
                            font-size: 1.6rem;
                          `}
                        >
                          {currentCard.date}
                        </LentenCardSubtitle>
                        <Poster src={banners[currentCard.banner]} alt="" />
                        <LentenCardSubtitle
                          $css={css`
                            text-align: center;
                            margin: 24px 0;
                            font-size: 1.6rem;
                          `}
                        >
                          {currentCard.gospel}
                        </LentenCardSubtitle>
                        <LentenCardTitle
                          $css={css`
                            text-align: center;
                            margin: 24px;

                            @media (min-width: 768px) {
                              margin: 24px 0;
                              max-width: 50%;
                            }
                          `}
                        >
                          {currentCard.gospelText}
                        </LentenCardTitle>
                      </div>
                    )}
                  </OpacityTransition>
                );
              }}
            </Transition>
          </div>
          <div
            style={{
              ...(step !== "complete" && {
                width: 0,
                overflow: "hidden",
              }),
            }}
          >
            <Transition in={step === "complete"} timeout={500}>
              {(state) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: 16,
                    transition: "opacity 0.5s ease-in",
                    opacity: state === "entered" ? 1 : 0,
                  }}
                >
                  <SubHeading>
                    May God be with you this Lenten season.
                  </SubHeading>
                  <Button
                    onClick={() => {
                      clearTimeout(currentTimeout.current);
                      reset();
                    }}
                  >
                    Back to Lenten Cards
                  </Button>
                </div>
              )}
            </Transition>
          </div>
          <BackButton
            style={{
              ...((step === "start" || step === "prepare") && {
                height: 0,
                width: 0,
                overflow: "hidden",
              }),
            }}
            onClick={() => {
              audioRef.current.pause();
              clearTimeout(currentTimeout.current);
              reset();
            }}
          >
            <BackIcon />
          </BackButton>
        </MeditationSection>
      </div>
    </Main>
  );
}

export default App;
